import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import KVForm from "../../components/KVForm";
import EUI64Field from "../../components/EUI64Field";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import ApplicationStore from "../../stores/ApplicationStore";


const styles = {
  formLabel: {
    fontSize: 12,
  },
};


export function dataRateToReadable(dr) {
  let map = {};
  map[0] = "DR0 (LoRa: SF12 / 125 kHz)"
  map[1] = "DR1 (LoRa: SF11 / 125 kHz)";
  map[2] = "DR2 (LoRa: SF10 / 125 kHz)";
  map[3] = "DR3 (LoRa: SF9 / 125 kHz)";
  map[4] = "DR4 (LoRa: SF8 / 125 kHz)";
  map[5] = "DR5 (LoRa: SF7 / 125 kHz)";
  map[6] = "DR6 (LoRa: SF7 / 250 kHz)";
  map[7] = "DR7 (FSK: 50 kbps)";
  map[8] = "DR8 (LR-FHSS CR1/3: 137 kHz)";
  map[9] = "DR9 (LR-FHSS CR2/3: 137 kHz)";
  map[10] = "DR10 (LR-FHSS CR1/3: 336 kHz)";
  map[11] = "DR11 (LR-FHSS CR2/3: 336 kHz)";
  return map[dr];
}

class DeviceForm extends FormComponent {
  constructor() {
    super();
    this.getDeviceProfileOption = this.getDeviceProfileOption.bind(this);
    this.getDeviceProfileOptions = this.getDeviceProfileOptions.bind(this);
    this.getDataRateOption = this.getDataRateOption.bind(this);
    this.getDataRateOptions = this.getDataRateOptions.bind(this);

    this.state = {
      tab: 0,
      variables: [],
      tags: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.setKVArrays(this.props.object || {});
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArrays(this.props.object || {});
    }
  }

  setKVArrays = (props) => {
    let variables = [];
    let tags = [];

    if (props.variables !== undefined) {
      for (let key in props.variables) {
        variables.push({key: key, value: props.variables[key]});
      }
    }

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    this.setState({
      variables: variables,
      tags: tags,
    });
  }

  getDataRateOption(id, callbackFunc) {
    callbackFunc({label: id.dataRate === "NoLimit" ? "No Limit" : dataRateToReadable(id.dataRate.substring(2)), value: id})
  }

  getDataRateOptions(search, callbackFunc) {
    let innerCallbackFunc = resp => {
      ServiceProfileStore.get(resp.application.serviceProfileID, resp => {
        let drList = [{label: "No Limit", "value": {"dataRate": "NoLimit"}}];
        for (let i = resp.serviceProfile.drMin; i <= resp.serviceProfile.drMax; i++) {
          drList.push({label: dataRateToReadable(i), value: {"dataRate": "DR" + i.toString()}})
        }
        callbackFunc(drList);
      });
    }

    ApplicationStore.get(this.props.match.params.applicationID, innerCallbackFunc);
  }

  getDeviceProfileOption(id, callbackFunc) {
    DeviceProfileStore.get(id, resp => {
      callbackFunc({label: resp.deviceProfile.name, value: resp.deviceProfile.id});
    });
  }

  getDeviceProfileOptions(search, callbackFunc) {
    DeviceProfileStore.list(0, this.props.match.params.applicationID, 999, 0, resp => {
      const options = resp.result.map((dp, i) => {return {label: dp.name, value: dp.id}});
      callbackFunc(options);
    });
  }

  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const variables = this.state.variables.map((obj, i) => <KVForm key={i} index={i} object={obj} onChange={this.onChangeKV("variables")} onDelete={this.onDeleteKV("variables")} />);
    const tags = this.state.tags.map((obj, i) => <KVForm key={i} index={i} object={obj} onChange={this.onChangeKV("tags")} onDelete={this.onDeleteKV("tags")} />);

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="General" />
          <Tab label="Variables" />
          <Tab label="Tags" />
        </Tabs>

        {this.state.tab === 0 && <div>
          <TextField
            id="name"
            label="Device name"
            helperText="The name may only contain words, numbers and dashes."
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            fullWidth
            required
          />
          <TextField
            id="description"
            label="Device description"
            margin="normal"
            value={this.state.object.description || ""}
            onChange={this.onChange}
            fullWidth
            required
          />
          {!this.props.update && <EUI64Field
            margin="normal"
            id="devEUI"
            label="Device EUI"
            onChange={this.onChange}
            value={this.state.object.devEUI || ""}
            fullWidth
            required
            random
          />}
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>Device-profile</FormLabel>
            <AutocompleteSelect
              id="deviceProfileID"
              label="Device-profile"
              value={this.state.object.deviceProfileID}
              onChange={this.onChange}
              getOption={this.getDeviceProfileOption}
              getOptions={this.getDeviceProfileOptions}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label="Disable frame-counter validation"
                control={
                  <Checkbox
                    id="skipFCntCheck"
                    checked={!!this.state.object.skipFCntCheck}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
            <FormHelperText>
              Note that disabling the frame-counter validation will compromise security as it enables people to perform replay-attacks.
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                  label="Send encrypted uplinks"
                  control={
                    <Checkbox
                        id="sendEncryptedUplink"
                        checked={!!this.state.object.sendEncryptedUplink}
                        onChange={this.onChange}
                        color="primary"
                    />
                  }
              />
            </FormGroup>
            <FormHelperText>
              Enabling this will disable the frame payload decryption.
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormLabel className={this.props.classes.formLabel}>Minimum Data Rate</FormLabel>
              <AutocompleteSelect
                  id="dataRateMin"
                  label="Minimum Data Rate"
                  value={this.state.object.dataRateMin}
                  onChange={this.onChange}
                  getOption={this.getDataRateOption}
                  getOptions={this.getDataRateOptions}
              />
              <FormLabel className={this.props.classes.formLabel}>Maximum Data Rate</FormLabel>
              <AutocompleteSelect
                  id="dataRateMax"
                  label="Maximum Data Rate"
                  value={this.state.object.dataRateMax}
                  onChange={this.onChange}
                  getOption={this.getDataRateOption}
                  getOptions={this.getDataRateOptions}
              />
            </FormGroup>
            <FormHelperText>
              Manually override allowed data rates.
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label="Device is disabled"
                control={
                  <Checkbox
                    id="isDisabled"
                    checked={!!this.state.object.isDisabled}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
            <FormHelperText>
              ChirpStack Network Server will ignore received uplink frames and join-requests from disabled devices.
            </FormHelperText>
          </FormControl>
        </div>}

        {this.state.tab === 1 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Variables can be used to substitute placeholders in for example integrations, e.g. in case an integration requires the configuration of a device specific token.
            </Typography>
            {variables}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("variables")}>Add variable</Button>
        </div>}

        {this.state.tab === 2 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              Tags can be used as device filters and are exposed on events as additional meta-data for aggregation.
            </Typography>
            {tags}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("tags")}>Add tag</Button>
        </div>}
      </Form>
    );
  }
}

export default withStyles(styles)(DeviceForm);

